import slider1 from '../../assets/images/slider1.jpeg'
import slider2 from '../../assets/images/slider2.jpg'
import slider3 from '../../assets/images/slider3.jpeg'

export class HeroSlider {
    public static list = [
        {
            title : 'Dolukanda Zip-Lining',
            image : slider1
        },
        {
            title : 'Dolukanda Rope Jumping',
            image : slider2
        },
        {
            title : 'Kitulgala kayaking',
            image : slider3
        }
    ]
}