/**
 * @class NavMenu
 * @description purpose of this component is to render the Navigation menu
 * @author Nawod Madhuvantha
 */

import { BrowserRouter } from "react-router-dom";
import logo from '../../../assets/images/logo.png';
import { NavList } from "../../constants/navigations";
import { Link } from "react-scroll";
import './navMenu.scss';
import { useState } from "react";
import { MdMenu, MdClose } from 'react-icons/md';

const NavMenu = () => {

    const [colorChange, setColorChange] = useState(false); 
    const [showNav, setShowNav] = useState(false);

    /**
     * change nav bar color
     */
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorChange(true);
        } else {
            setColorChange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

    return(
        <BrowserRouter>
            <nav className={`nav-row flex main-container ${colorChange && 'solid'}`}>
                <Link
                    to={'home'}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    delay={500}
                >
                    <img className="nav-logo" src={logo} alt="logo" />
                </Link>
                <div
                    className="mobile-menu-icon"
                    onClick={() => setShowNav(!showNav)}
                    role="button"
                    onKeyDown={() => setShowNav(!showNav)}
                    tabIndex={0}
                >
                    <MdMenu />
                </div>
                <div className="nav-list-wrapper">
                    <ul className={`nav-list flex  ${!showNav && 'hide-items'}`}>
                    <div
                        className="closeNavIcon"
                        onClick={() => setShowNav(!showNav)}
                        role="button"
                        onKeyDown={() => setShowNav(!showNav)}
                        tabIndex={0}
                        >
                        <MdClose />
                    </div>
                        {
                            NavList.list.map((item, index) => {
                                return(
                                <li className="nav-item" key={index}>
                                    <Link
                                        to={item.url}
                                        spy={true}
                                        smooth={true}
                                        offset={0}
                                        duration={500}
                                        delay={500}
                                    >
                                        {item.title}
                                    </Link>
                                </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </nav>
        </BrowserRouter>
    )
}

export default NavMenu;