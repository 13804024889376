/**
 * @class VideoBackground
 * @description purpose of this component is to visualize our adventure activities video clips
 * @author Nawod Madhuvantha
 */

import ReactPlayer from 'react-player';
import './videoBackground.scss';
import { Link } from 'react-scroll';
import Button from '../../shared/components/button';
import Thumbnail from '../../assets/images/thumbnail.png';
import { useState } from 'react';

const VideoBackground = () => {

    const [isLoad, setIsLoad] = useState(false)

    return(
        <section className='main-container video-wrapper' id='video'>
            <div className="player-wrapper">
                { 
                    !isLoad && (
                        <img src={Thumbnail} alt="" className='thumbnail' />
                    )
                    
                }
                <ReactPlayer
                    className="react-player"
                    url={"https://youtu.be/BT2yZvCDZR0"}
                    loop={true}
                    playing={true}
                    width={"100%"}
                    height={"56.2vw"}
                    controls={false}
                    pip={false}
                    light={false}
                    muted={true}
                    onReady={()=>setIsLoad(true)}
                    config={{
                        file: {
                        attributes: {
                            crossOrigin: "anonymous",
                        },
                        },
                    }}
                />
            </div>
            <div className="title-section">
                <div className="large-font">Feel the rush of adrenaline and take the leap at Sri Lanka’s highest rope jump!</div>
                <Link
                        to={'contact'}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        delay={500}
                    ><Button title={'Contact Us'} clickAction={()=>{}}/></Link>
            </div>
        </section>
    )
}

export default VideoBackground;