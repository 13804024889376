/**
 * @class Events
 * @description purpose of this component is to visualize upcoming events
 * @author Nawod Madhuvantha
 */

import { useDispatch, useSelector } from 'react-redux';
import { UpcomingEvents } from '../../shared/constants/events';
import './events.scss';
import { eventAction } from '../../store/eventSlice';
import ReactGA from "react-ga";
import { useEffect } from 'react';

const Events = () => {

    const dispatch = useDispatch();
    const { selectedEvent } = useSelector( ( state : any ) => state.eventStates );

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname, [], 'Event Page');
    },[]);

    /**
     * change active tool
     * @param event 
     */
    const selectEvent = (event : any) => {
        ReactGA.event({
            category : event.name,
            action : 'select',
        })
        dispatch(eventAction.changeSelectedEvent(event))
    }

    /**
     * book an event
     * @param id 
     */
    // const BookEvent = (id:string) => {
    //     console.log(id)
    // }

    return(
        <section className="main-container events" id='events'>
            <div className="title-section">
                <div className="intro">What is upcoming?</div>
                <div className="h1 sub-title">Adrenaline pumping<br/>adventure events</div>
            </div>
            <div className="events-wrapper">
                <div className="events-container">
                    {
                        UpcomingEvents.list.map((item) => {
                            return(
                                <div className='key-wrapper' key={item.id}>
                                <div className="event-item-image">
                                    <img src={item.image} alt={item.name} />
                                </div>
                                <div 
                                    className={`event-item ${item.id === selectedEvent.id && 'active'}`} 
                                    onMouseEnter={()=>selectEvent(item)}
                                    onClick={()=>selectEvent(item)}
                                >
                                    <div className="event-date large-font">
                                        {item.date}
                                    </div>
                                    <div className="event-data">
                                        <div className="event-title large-font">
                                            {item.name}
                                        </div>
                                        <div className="event-inclusion">
                                            {
                                                item.inclusion.map((inclusion,index) => {
                                                    return(
                                                        <div className="inclusion-item" key={index} title={inclusion.title}>
                                                            {inclusion.icon}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="price-row">
                                            <div className="event-price regular-font">
                                                LKR {item.price}
                                            </div>
                                            <div className="hr"></div>
                                            <a  className="booking-btn" href={item.url} target="_blank" rel="noreferrer">
                                                Book Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="events-image-container">
                    <img src={selectedEvent.image} className='event-image' alt={selectedEvent.name} />
                </div>
            </div>
        </section>
    )
}

export default Events;