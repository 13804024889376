/**
 * @class LoadingSlice
 * @description purpose of this slice is to manage loader
 * @author Nawod Madhuvantha
 */
import { createSlice } from "@reduxjs/toolkit";
;

let initialState = {
    isLoading : false
};

const LoadingSlice = createSlice({
    name: "loading-state",
    initialState: initialState,
    reducers: {
        //change selected event
        changeLoadingState(state, action) {
            state.isLoading = action.payload;
        },
    }
    });

export const loadingAction = LoadingSlice.actions;
    
export default LoadingSlice;