/**
 * @class Contact
 * @description purpose of this component is to render the contact form
 * @author Nawod Madhuvantha
 */

import { useEffect, useState } from 'react';
import { ContactInfo } from '../../shared/constants/links';
import './contact.scss';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga";
import { useDispatch } from 'react-redux';
import { loadingAction } from '../../store/loadingSlice';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname, [], 'Contact Page');
    },[]);
    const dispatch = useDispatch();

    /**
     * display a notification
     * @param type 
     * @param msg 
     */
    const Notify = (type : string, msg : string) => {
        dispatch(loadingAction.changeLoadingState(false))
        if (type === 'warn') {
        toast.error(msg);
        } else {
        toast.success(msg);
        }
    };

    /**
     * sent an email
     */
    const sentEmail = () => {
        ReactGA.event({
            category : `sent mail by ${name}`,
            action : 'sent mail',
        })
        dispatch(loadingAction.changeLoadingState(true))

        const templateParams = {
            from_name: name,
            message: message,
            reply_to : email,
            contact : contact
        };
  
        emailjs
        .send(
            'service_nk8waap',
            'template_wwrj1kh',
            templateParams,
            'yCfX6J_WKutGoLYFb'
        )
        .then((res:any) => {
            Notify('success', 'Your message successfully sent!');
            setName('');
            setEmail('');
            setMessage('');
            setContact('');
        })
        .catch((err:any) => Notify('warn', "Sorry! Your message didn't sent"));
    };

    const setContactNumber = (e:any) => {
        if(e){
            setContact(e)
        }
    }

    return(
        <section className="contact-container main-container" id='contact'>
            <div className="title-section">
                <div className="intro">How to connect?</div>
                <div className="h1 sub-title">Get in touch<br/>with us</div>
            </div>
            <div className="contact-section">
                <div className="contact-details">
                    {
                        ContactInfo.info.map((item, index)=>{
                            return(
                                <div className='contact-detail-item' key={index}>
                                    <div className="icon">{item.icon}</div>
                                    <div className="info">
                                        <a className='large-font' href={item.path} target="_blank" rel="noreferrer">
                                            {item.title}
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <form className="contact-form">
                    <div className="form-group">
                        <label htmlFor="name" className='large-font'>
                            Your name
                            <input
                                type="text"
                                id="name"
                                name="name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </label>
                        </div>
                        <div className="form-group">
                        <label htmlFor="email" className='large-font'>
                            Your email
                            <input
                                type="email"
                                id="email"
                                name="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        </div>
                        <div className="form-group">
                        <label htmlFor="contact" className='large-font'>
                            Your contact
                            <PhoneInput
                                international
                                defaultCountry="LK"
                                value={contact}
                                onChange={(e)=>setContactNumber(e)}
                                error={contact ? (isValidPhoneNumber(contact) ? undefined : 'Invalid phone number') : 'Phone number required'}
                            />
                        </label>
                        </div>
                        <div className="form-group">
                        <label htmlFor="message" className='large-font'>
                            Your message
                            <textarea
                                id="message"
                                name="message"
                                required
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </label>
                        </div>
                        <div className={`submit-btn ${(name !== '' && email !== '' && contact !== '' && message !== '') ? 'active' : 'deactive'}`} onClick={sentEmail}>Send</div>
                    </form>
                </div>
                <ToastContainer />
        </section>
    )
}

export default Contact;