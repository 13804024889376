/**
 * @class HomeHeading
 * @description purpose of this component is to render heading section of home page
 * @author Nawod Madhuvantha
 */

import { Link } from 'react-scroll';
import Button from '../../../../shared/components/button';
import { SocialLinks } from '../../../../shared/constants/links';
import Slider from '../slider';
import './homeHeading.scss';
import ReactGA from "react-ga";

const HomeHeading = () => {

    /**
     * navigate to events section
     */
    const navigateToEvents = () => {
        ReactGA.event({
            category : 'Navigate To Event',
            action : 'click'
        })
    }

    return(
        <div className='main-container flex home-heading-wrapper'>
            <div className="hero-section">
                <div className="main-heading">
                    THE MARVEL
                </div>
                <div className="main-heading large">
                    SRI LANKA
                </div>
                <div className="description large-font">
                    Join us today and unleash your inner superhero in the land of marvels! 
                </div>
                <div className="action-row">
                    <Link
                        to={'events'}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        delay={500}
                    ><Button title={'Explore Events'} clickAction={navigateToEvents}/></Link>
                    <div className="social-icons">
                    {
                        SocialLinks.links.map((item, index) => {
                            return(
                                    <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={index}
                                    className="icon"
                                    >
                                    {item.icon}
                                    </a>
                            )
                        })
                    }
                </div>
                </div>
            </div>
            <div className="slider-section">
                <Slider/>
            </div>
        </div>
    )
}

export default HomeHeading;