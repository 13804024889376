/**
 * @class Footer
 * @description purpose of this component is to render the footer
 * @author Nawod Madhuvantha
 */

import { BrowserRouter } from "react-router-dom";
import logo from '../../../assets/images/logo.png';
import { NavList } from "../../constants/navigations";
import './footer.scss';
import FooterCol from "./components/footerCol";
import { ContactInfo, SocialLinks } from "../../constants/links";

const Footer = () => {

    return(
        <BrowserRouter>
            <div className="footer-wrapper">
                <div className="footer flex main-container">
                    <div className="footer_col1">
                        <div className="footer_logo">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="footer-desc regular-font">
                        Marvel Sri Lanka takes pride in showcasing the natural wonders of this tropical paradise. Our highly skilled and experienced guides will lead you on epic expeditions, ensuring your safety while pushing your limits.
                        </div>
                    </div>
                    <div className="footer_col2">
                    <FooterCol
                        heading="Navigation"
                        Links={NavList.list.map((item)=>{
                            return(
                                {
                                    title : item.title,
                                    path : item.url,
                                    type : 'Link'
                                }
                            )
                        })}
                    />
                    </div>
                    <div className="footer_col3">
                    <FooterCol
                        heading="Contact Info"
                        Links={ContactInfo.info.map((item)=>{
                            return(
                                {
                                    title : item.title,
                                    path : item.path,
                                }
                            )
                        })}
                    />
                    </div>
                    <div className="footer_col4">
                    <FooterCol
                        heading="Social Links"
                        Links={SocialLinks.links.map((item)=>{
                            return(
                                {
                                    title : item.title,
                                    path : item.link,
                                }
                            )
                        })}
                    />
                    </div>
                </div>
            </div>
            <div className="copyright">
                <a 
                    className="copyright-title regular-font" 
                    href="https://madhuvantha.netlify.app/" 
                    target="_blank" 
                    rel="noreferrer"
                >
                    ©️ 2024 The Marvel Sri Lanka - Nawod Madhuvantha 
                </a>
            </div>
        </BrowserRouter>
    )
}

export default Footer;