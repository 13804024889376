/**
 * @class FooterCol
 * @description purpose of this component is to render the footer col item
 * @author Nawod Madhuvantha
 */

import { Link } from "react-scroll";
import './footerCol.scss';
import FooterColItem from "../../../../models/footer";


const FooterCol = (props : FooterColItem) => {

  return (
    <div className="footer-col">
      <h1 className="heading">{props.heading}</h1>
      <ul className="link-list">
        {props.Links.map((item, index) => (
          <li key={index}>
            {item.type === 'Link' ? (
              <Link 
                to={item.path}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                delay={500}
              >{item.title}</Link>
            ) : (
              <a href={item.path} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FooterCol;