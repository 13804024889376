/**
 * @class eventSlice
 * @description purpose of this slice is to manage event section data
 * @author Nawod Madhuvantha
 */
import { createSlice } from "@reduxjs/toolkit";
import { UpcomingEvents } from "../shared/constants/events";
;

let initialState = {
    selectedEvent : UpcomingEvents.list[0]
};

const eventSlice = createSlice({
    name: "event-data",
    initialState: initialState,
    reducers: {
        //change selected event
        changeSelectedEvent(state, action) {
            state.selectedEvent = action.payload;
        },
    }
    });

export const eventAction = eventSlice.actions;
    
export default eventSlice;