/**
 * @class DailyEvents
 * @description purpose of this component is to visualize upcoming events
 * @author Nawod Madhuvantha
 */

import ReactGA from "react-ga";
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './dailyEvents.scss';
import { DailyEventsList } from '../../shared/constants/dailyEvents';
import { DailyEventItem } from '../../shared/models/events';

const DailyEvents = () => {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname, [], 'Daily Event Page');
    },[]);

    /**
     * book an event
     * @param id 
     */
    // const BookEvent = (id:string) => {
    //     console.log(id)
    // }

    return(
        <section className="main-container daily-event-wrapper" id='events'>
            <div className="title-section">
                <div className="intro">Can we join any day?</div>
                <div className="h1 sub-title">Explore our<br/>daily activities</div>
            </div>
            <Swiper
                grabCursor={true}
                centeredSlides={true}
                spaceBetween={30}
                autoplay={{
                delay: 2500,
                disableOnInteraction: false,
                }}
                loop={true}
                slidesPerView={'auto'}
                pagination={false}
                modules={[Autoplay,Pagination]}
                className="daily-event-swiper"
            >
                {
                    DailyEventsList.list.map((item:DailyEventItem)=>{
                        return(
                            <SwiperSlide className='activity-swiper-card' key={item.id}>
                                <div className="activity-card-wrapper">
                                    <img src={item.image} alt={item.name} />
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}

export default DailyEvents;