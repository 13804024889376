import './App.scss';
import Home from './pages/home';
import About from './pages/about';
import Activities from './pages/activities';
import Events from './pages/events';
import NavMenu from './shared/components/navMenu';
import Footer from './shared/components/footer';
import Contact from './pages/contact';
import VideoBackground from './pages/videoBackground';
import ReactGA from "react-ga";
import { useSelector } from 'react-redux';
import DailyEvents from './pages/dailyEvents';
// import FeatureEvents from './pages/featureEvents';
// import Gallery from './pages/gallery';

ReactGA.initialize('G-Q8QFPWZDZB')

const App = () =>{
  const { isLoading } = useSelector( ( state : any ) => state.loadingStates );

  return(
      <div className={`main-div ${isLoading && 'loading'}`}>
        <NavMenu/>
        <Home/>
        <VideoBackground/>
        <About/>
        <Activities/>
        {/* <FeatureEvents/> */}
        <Events/>
        <DailyEvents />
        <Contact/>
        {/* <Gallery/> */}
        <Footer/>
      </div>
  )
}

export default App;
