import { configureStore } from "@reduxjs/toolkit";
import eventSlice from "./eventSlice";
import LoadingSlice from "./loadingSlice";

const store = configureStore({
    // middleware: (getDefaultMiddleware)
    reducer: {
        eventStates: eventSlice.reducer,
        loadingStates : LoadingSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;