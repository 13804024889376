/**
 * @class Loader
 * @description purpose of this component is to render loader element
 * @author Nawod Madhuvantha
 */

import { BounceLoader } from 'react-spinners';
import './loader.scss';
import { useSelector } from 'react-redux';

const Loader = () => {

    const { isLoading } = useSelector( ( state : any ) => state.loadingStates );

    return(
        <div className={`loader-wrapper ${isLoading && 'loading'}`}>
            <BounceLoader 
                color="#e2691d" 
            />
        </div>
    )
}

export default Loader;