/**
 * @class About
 * @description purpose of this component is to render the about us page
 * @author Nawod Madhuvantha
 */

import './about.scss';
import 'aos/dist/aos.css';
import {BsHexagonFill} from 'react-icons/bs';
import AOS from 'aos';

import img1 from '../../assets/images/about_bg_1.png';
import img2 from '../../assets/images/about_bg_2.png';
import img3 from '../../assets/images/about_bg_3.png';
import img4 from '../../assets/images/about_bg_4.png';
import { useEffect } from 'react';
import ReactGA from "react-ga";

const About = () => {

    useEffect(() => {
      AOS.init();
      ReactGA.pageview(window.location.pathname, [], 'About Page');
    }, [])
    
    return(
        <section className="about-container" id='about'>
            <div className="title-section">
                <div className="intro">Who we are?</div>
                <div className="h1 sub-title">We are<br/>the Marvel Sri Lanka</div>
            </div>
            <div className="content-wrapper main-container">
                <div className="content flex">
                    <div className="text-block flex">
                        <BsHexagonFill className='hexa'></BsHexagonFill>
                        <div className="block-details">
                            <p className='block-title large-font'>About Us</p>
                            <p className='description regular-font'>
                            The Marvel Sri Lanka takes pride in showcasing the natural wonders of
                            this tropical paradise. Our highly skilled and experienced guides will lead you on epic expeditions, ensuring your safety while pushing your limits. Feel the rush as you descend cliffs during abseiling, conquer roaring rapids while water rafting, soar through the treetops with our thrilling zip line courses, and conquer challenging terrains on our hiking and trekking adventures. Paddle through stunning waterways and pristine lakes on our kayaking excursions, exploring hidden gems and marveling at the lush surroundings.
                            </p>
                        </div>
                    </div>
                    <img 
                        src={img1} 
                        alt="" 
                        className='top-left-img'
                        data-aos="fade-right"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                    />
                    <div className="text-block flex left">
                        <BsHexagonFill className='hexa'></BsHexagonFill>
                        <div className="block-details">
                            <p className='block-title large-font'>Our Vision</p>
                            <p className='description regular-font'>
                                Our vision at "The Marvel Sri Lanka" is to become the foremost name associated with adventure tourism in Sri Lanka. We aspire to be the catalysts that draw thrill-seekers and nature enthusiasts from around the globe to discover the adrenaline-pumping experiences and untouched splendor that Sri Lanka has to offer.
                            </p>
                        </div>
                    </div>
                    <img 
                        src={img2} 
                        alt="" 
                        className='middle-right-img'
                        data-aos="fade-left"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                    />
                    <img 
                        src={img3} 
                        alt="" 
                        className='middle-right-img-small'
                        data-aos="fade-left"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                    />
                    <div className="text-block flex">
                        <BsHexagonFill className='hexa'></BsHexagonFill>
                        <div className="block-details">
                            <p className='block-title large-font'>Our Mission</p>
                            <p className='description regular-font'>
                            Driven by a commitment to excellence, safety, and sustainable adventure tourism, our mission is to curate unforgettable experiences while preserving the natural beauty and cultural heritage of Sri Lanka. We strive to facilitate exhilarating adventures that not only leave lasting memories but also contribute positively to local communities and the tourism industry.
                            </p>
                        </div>
                    </div>
                    <img 
                        src={img4} 
                        alt="" 
                        className='bottom-left-img'
                        data-aos="fade-right"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                    />
                </div>
            </div>
        </section>
    )
}

export default About;