import ABSEIL from '../../assets/images/activities/abseiling.jpeg';
import ROPE_JUMP from '../../assets/images/activities/rope_jumping.jpeg';
import KAYAKING from '../../assets/images/activities/kayaking.jpeg';
import RAFTING from '../../assets/images/activities/rafting.jpeg';
import ZIP_LINE from '../../assets/images/activities/zip_lining.jpeg';
import PRESHOOT from '../../assets/images/activities/pre_shoot.jpeg';

export class ActivityList {
    public static list = [
        {
          id: 1,
          title: 'Rope Jump',
          description: "Are you ready for the ultimate thrill? Join us for a rope jumping adventure in Sri Lanka, where you can experience the exhilaration of free falling from a height of over meters. We are the pioneers to introduce this activity to Sri Lanka, and we guarantee you a safe and unforgettable experience. Don't miss this chance to see the beauty of nature from a different perspective. Book your spot today and get ready to jump!",
          image: ROPE_JUMP,
          places: [
            {
              id : 1,
              place : 'Lakshapana Waterfall'
            },
            {
              id : 2,
              place : 'Dolukanda Cliff'
            },
          ]
        },
        {
          id: 2,
          title: 'Abseiling',
          description: 'If you are looking for an adrenaline rush adventure in Sri Lanka, abseiling is the perfect choice. You can experience the thrill of descending from steep cliffs, rocks and waterfalls, while enjoying the scenic beauty of the island. Waterfall abseiling is especially exciting, as you feel the spray of water on your face and hear the roar of the falls. Abseiling adventure in Sri Lanka is a must-try for anyone who loves adventure and nature.',
          image: ABSEIL,
          places: [
            {
              id : 1,
              place : 'Bambarakanda Waterfall'
            },
            {
              id : 2,
              place : 'Lakshapana Waterfall'
            },
            {
              id : 3,
              place : 'Gartmore Waterfall'
            },
            {
              id : 4,
              place : 'Bomburu Waterfall'
            },
            {
              id : 5,
              place : 'Aberdeen Waterfall'
            },
            {
              id : 6,
              place : 'Kirindi Waterfall'
            },
            {
              id : 7,
              place : 'Sandun Waterfall'
            },
            {
              id : 8,
              place : 'Mannakethi Waterfall'
            },
          ]
        },
        {
          id: 3,
          title: 'Kayaking',
          description: 'Kayaking in Sri Lanka is an unforgettable experience. You can explore the stunning coastline, the lush rainforest, and the wildlife-rich lagoons. You can also challenge yourself with rapids and waves. Kayaking in Sri Lanka is a perfect way to enjoy nature and adventure at the same time.',
          image: KAYAKING,
          places: [
            {
              id : 1,
              place : 'Mahiyanganaya to Trincomali (Mahaweli river)'
            },
            {
              id : 2,
              place : 'Mahiyanganaya to Manampitiya (Mahaweli river)'
            },
            {
              id : 3,
              place : 'Kithulgala to Colombo (Kelani river)'
            },
            {
              id : 4,
              place : 'Kelaniya to Colombo (Kelani river)'
            },
          ]
        },
        {
          id: 4,
          title: 'Rafting',
          description: 'Get ready for an amazing white water rafting adventure in Sri Lanka! You will ride the waves of the Kelani River, one of the best rafting spots in the country. Feel the excitement and the challenge as you steer your boat through the water. This is a fun and unforgettable experience!',
          image: RAFTING,
          places: [
            {
              id : 1,
              place : 'Kithulgala white water rafting'
            }
          ]
        },
        {
          id: 5,
          title: 'Zip-Lining',
          description: "Experience the thrill of zip lining in Sri Lanka, where you can soar above the lush green forests and enjoy breathtaking views of the landscape. Feel the adrenaline rush as you glide through the air, and marvel at the natural beauty of this island paradise. Zip lining is a safe and fun adventure for everyone, and a great way to explore Sri Lanka from a different perspective. Don't miss this opportunity to have an unforgettable zip line adventure in Sri Lanka!",
          image: ZIP_LINE,
          places: [
            {
              id : 1,
              place : 'Kataram Oya'
            },
            {
              id : 2,
              place : 'Dolukanda Cliff'
            },
          ]
        },
        {
          id: 6,
          title: 'Pre-Wedding Adventure',
          description: 'If you are looking for a unique and thrilling way to celebrate your love, why not try a Pre-Wedding Adventure photoshoot? You and your partner will get to experience the adrenaline rush of waterfall abseiling, ziplining, and abseiling in some of the most scenic locations. Imagine the stunning photos you will have as you descend from the heights, embrace each other in the water, and soar through the air. This is a once-in-a-lifetime opportunity to capture your romance and adventure in one unforgettable package.',
          image: PRESHOOT,
          places: [
            {
              id : 1,
              place : 'Service on Demand'
            }
          ]
        },
      ]
}