export class NavList {
    public static list = [
        {
            title : 'Home',
            url : 'home'
        },
        {
            title : 'About us',
            url : 'about'
        },
        {
            title : 'Activities',
            url : 'activities'
        },
        {
            title : 'Upcoming Events',
            url : 'events'
        },
        {
            title : 'Contact Us',
            url : 'contact'
        }
    ]
}