/**
 * @class Slider
 * @description purpose of this component is to render a slider in home page
 * @author Nawod Madhuvantha
 */

import './slider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { HeroSlider } from '../../../../shared/constants/images';

const Slider = () => {

    return(
        <div className='slider-wrapper'>
           <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2.5,
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                }}
                modules={[Autoplay,EffectCoverflow, Pagination, Navigation]}
                className="swiper_container"
            >
            {
                HeroSlider.list.map((item, index) => {
                    return(
                        <SwiperSlide key={index}>
                            <div className="slide">
                                <img src={item.image} alt={item.title} title={item.title} />
                            </div>
                        </SwiperSlide>
                    )
                })
            }
            <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
           </Swiper>
        </div>
    )
}

export default Slider;