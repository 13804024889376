import {
    FaFacebook,
    FaInstagram,
    FaTiktok,
    FaWhatsapp
} from 'react-icons/fa';
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

export class SocialLinks {
    public static readonly FACEBOOK = "https://www.facebook.com/themarvelsrilanka/";
    public static readonly WHATSAPP = "https://chat.whatsapp.com/DkUOuqm6QlHBXHGV1aWM35";
    public static readonly INSTAGRAM = "https://www.instagram.com/themarvelsrilanka/";
    public static readonly TIKTOK = "https://www.tiktok.com/@themarvelsrilanka";
    public static readonly links = [
        {
            title: "FACEBOOK",
            link: SocialLinks.FACEBOOK,
            icon : <FaFacebook />
        },
        {
            title: "INSTAGRAM",
            link: SocialLinks.INSTAGRAM,
            icon : <FaInstagram />
        },
        {
            title: "TIK-TOK",
            link: SocialLinks.TIKTOK,
            icon : <FaTiktok />
        },
        {
            title: "WHATSAPP",
            link: SocialLinks.WHATSAPP,
            icon : <FaWhatsapp />
        },

    ]
}

export class ContactInfo {
    public static MOBILE_NO = {
        title: '+94 78 468 2799',
        path: 'tel:+94784682799',
        icon: <BsFillTelephoneFill/>
    };
    public static MAIL = {
        title: 'themarvelsrilanka@gmail.com',
        path: 'mailto:themarvelsrilanka@gmail.com',
        icon: <MdEmail/>
    };
    public static ADDRESS = {
        title: 'Kelaniya, Sri Lanka',
        path: 'https://maps.app.goo.gl/XxY2HVLYmZYpsFoD8',
        icon: <FaLocationDot/>
    };

    public static info = [this.MOBILE_NO,this.MAIL,this.ADDRESS]
}