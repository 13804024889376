import E1 from '../../assets/images/events/daily/01.jpeg';
import E2 from '../../assets/images/events/daily/02.jpeg';
import E3 from '../../assets/images/events/daily/03.jpeg';
import E4 from '../../assets/images/events/daily/04.jpeg';
import E5 from '../../assets/images/events/daily/05.jpeg';


export class DailyEventsList {
    public static list = [
        {
            id : '01',
            name : 'Abseiling',
            image : E1,
        },
        {
            id : '02',
            name : 'Kayaking',
            image : E2,
        },
        {
            id : '03',
            name : 'Canyoning',
            image : E3,
        },
        {
            id : '04',
            name : 'Rafting',
            image : E4,
        },
        {
            id : '05',
            name : 'cycling',
            image : E5,
        },
    ]
}
