/**
 * @class Button
 * @description purpose of this component is to render button element
 * @author Nawod Madhuvantha
 */

import './button.scss';

interface Props {
    title : string;
    clickAction : Function;
}

const Button = ({title, clickAction} : Props) => {

    /**
     * handel click action
     */
    const onClickAction = () => {
        if(clickAction){
            clickAction()
         }
    }

    return(
        <div className="button-wrapper" onClick={onClickAction}>
            {title}
        </div>
    )
}

export default Button;