import '../../assets/images/background1.JPG';
import { TbDrone } from "react-icons/tb";
import { BsFillCameraFill } from "react-icons/bs";
import { IoFastFood } from "react-icons/io5";
import { FaShuttleVan } from "react-icons/fa";
import E2 from '../../assets/images/events/02.jpeg';
import E3 from '../../assets/images/events/03.jpeg';
import E4 from '../../assets/images/events/04.jpeg';
import E5 from '../../assets/images/events/05.jpeg';
import E6 from '../../assets/images/events/06.jpeg';


export class EventInclusions {
    public static readonly DRONE = {
        title : 'Drone Footages',
        icon : <TbDrone/>
    }
    public static readonly PHOTO = {
        title : 'Camera Footages',
        icon : <BsFillCameraFill/>
    }
    public static readonly FOOD = {
        title : 'Food & Beverages',
        icon : <IoFastFood/>
    }
    public static readonly TRANSPORT = {
        title : 'Transport',
        icon : <FaShuttleVan/>
    }
}

export class UpcomingEvents {
    public static list = [
        {
            id : '02',
            name : 'Bambarakanda Fall - Abseiling',
            date : '09/07',
            price : '23,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E2,
            url : 'https://forms.gle/1T2xVd1czFc8BwWB8'
        },
        {
            id : '03',
            name : 'Lakshapana - Rope Jump',
            date : '09/14',
            price : '36,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E3,
            url : 'https://forms.gle/Cyd7KsKueibWuK9M7'
        },
        {
            id : '04',
            name : 'Lakshapana - Rope Jump',
            date : '09/15',
            price : '36,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E4,
            url : 'https://forms.gle/JXV8my2TcJ1yiM9A8'
        },
        {
            id : '05',
            name : 'Lakshapana Fall - Abseiling',
            date : '09/16',
            price : '16,500',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E5,
            url : 'https://forms.gle/kv3nqhucsLiQSXPR6'
        },
        {
            id : '06',
            name : 'Discover Ella - 2 Days',
            date : '09/28-29',
            price : '30,000',
            inclusion : [EventInclusions.DRONE, EventInclusions.PHOTO, EventInclusions.FOOD, EventInclusions.TRANSPORT],
            image : E6,
            url : 'https://forms.gle/xu3MfKwxa14k5Xqr6'
        },
    ]
}
