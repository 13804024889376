/**
 * @class Home
 * @description purpose of this component is to render the home page
 * @author Nawod Madhuvantha
 */

import { useEffect } from "react";
import HomeHeading from "./components/homeHeading";
import './home.scss';
import ReactGA from "react-ga";

const Home = () => {

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname, [], 'home page');
    },[]);

    return(
        <section className="bgContainer" id='home'>
            <HomeHeading/>
        </section>
    )
}

export default Home;