/**
 * @class Activities
 * @description purpose of this component is to visualize adventure activities
 * @author Nawod Madhuvantha
 */

import './activities.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { ActivityList } from '../../shared/constants/activities';
import ActivityCardItem from '../../shared/models/activity';
import { useEffect, useState } from 'react';
import ReactGA from "react-ga";


const Activities = () => {

    const [realIndex, setRealIndex] = useState(0)

    useEffect(()=>{
        ReactGA.pageview(window.location.pathname, [], 'Activity Page');
    },[]);

    return(
        <section className='main-container activity-wrapper' id='activities'>
            <div className="title-section">
                <div className="intro">What we do?</div>
                <div className="h1 sub-title">We arrange<br/>adventure activities</div>
            </div>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                slidesPerView={'auto'}
                pagination={false}
                onSlideChange={(swiper:any) => (setRealIndex(swiper.realIndex))}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="activity-swiper"
            >
                {
                    ActivityList.list.map((item:ActivityCardItem)=>{
                        return(
                            <SwiperSlide className='activity-swiper-card' key={item.id}>
                                <div className="activity-card-wrapper">
                                    <img src={item.image} alt={item.title} />
                                    <div className="card-content">
                                        <div className="activity-title large-font">
                                            {item.title}
                                        </div>
                                        <div className="activity-desc regular-font">
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <div className="activity-location-wrapper">
                <div className="h2 sub-title">We are doing<br/>adventures in these locations</div>
                {
                    ActivityList.list[realIndex].places.map((item:any,index)=>{
                        return(
                            <div className="activity-location large-font" key={index}>{item.place}</div>
                        )
                    })
                }
                
            </div>
        </section>
    )
}

export default Activities;
